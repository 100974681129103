.navbar-container {
    display: flex;
    align-items: left;
    width: 100%;
    overflow: hidden;
    background-color: #2c3643;
}

.navbar-item {
    float: left;
    display: block;
    color: #f2f2f2;
    text-align: center;
    padding: 16px 24px;
    text-decoration: none;
    font-size: 17px;
}

.navbar-item:hover,
.navbar-item:focus,
.navbar-item:active {
  background-color: #0f1318;
}